import Vue from 'vue'
import App from './App.vue'
import i18n from "./plugins/element";
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import './config';

Vue.use(Element);

Vue.config.productionTip = false

new Vue({
  el: "#app",
  i18n,
  render: h => h(App),
}).$mount('#app')
