<template>
  <div id="app">
    <div class="content" v-loading="loading">
      <p style="text-align: right">
        <span class="switch-lang" @click="switchLang">{{ lang }}</span>
      </p>
      <h3 class="title">{{ $t('t16') }}</h3>
      <p class="tip">{{ chainText.t3 }}</p>
      <div class="tc" v-if="!address">
        <el-button class="wd_200" type="primary" @click="connect">{{ $t('t1') }}</el-button>
      </div>
      <div class="tc" v-else-if="!nulsAddress">
        <el-button class="wd_200" type="primary" @click="derivedAddress">{{ chainText.t5 }}</el-button>
      </div>
      <template v-else>
        <template v-if="currentChain === 'NULS'">
          <p class="tip" style="margin-bottom: 0">{{ $t('t18') }}</p>
          <p class="tip" style="margin-bottom: 0">{{ $t('t19') }}</p>
          <p class="tip">{{ $t('t22') }}</p>
        </template>
        <template v-else>
          <p class="tip">{{ $t('t23') }}</p>
        </template>
        <el-form class="message-form" ref="form" :model="form" label-width="80px" label-position="top" :rules="rules">
          <el-form-item class="current-account">
            <template v-slot:label>
              <p class="flex-between">
                <span class="current">
                  {{ $t('t2') }}
                  {{  $t('accountType.' + accountInfo.state) }}
                </span>
              </p>
            </template>
            <el-input class="pc" :value="nulsAddress" disabled></el-input>
            <el-input class="mobile" :value="superLong(nulsAddress)" disabled></el-input>
          </el-form-item>
          <div class="pocm-list" >
            <p v-if="accountInfo.state&&accountInfo.state !== 'NotLock'">{{ $t('t20') }}{{accountInfo.stack}}{{ chainSymbol }}</p>
            <template v-if="accountInfo.pocmList && accountInfo.pocmList.length">
              <P>{{ $t('t21') }}</P>
              <div class="pocm-item flex-between" v-for="item in accountInfo.pocmList" :key="item.name">
                <span>{{ item.name }}</span>
                <span>{{ item.amount }}{{ chainSymbol }}</span>
              </div>
            </template>
          </div>
          <div class="pocm-list"></div>
          <el-form-item :label="$t('t3')" prop="contact">
            <el-input v-model="form.contact" :placeholder="$t('t7')"></el-input>
          </el-form-item>
          <el-form-item :label="chainText.t4" prop="mail">
            <el-input v-model="form.mail" :placeholder="$t('t8')"></el-input>
          </el-form-item>
          <el-form-item :label="chainText.t1" prop="targetAddress">
            <el-input v-model="form.targetAddress" :placeholder="chainText.t2"></el-input>
          </el-form-item>
          <div class="tc pt_20">
            <el-button class="wd_200" type="primary" :disabled="disableSubmit" @click="createTx">{{ $t('t6') }}</el-button>
          </div>
<!--          <el-button @click="sign">xxxxx</el-button>-->
        </el-form>
      </template>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import nerve from 'nerve-sdk-js';
const Signature = require('elliptic/lib/elliptic/ec/signature');
const txsignatures = require('nerve-sdk-js/lib/model/txsignatures');
import BufferReader from 'nerve-sdk-js/lib/utils/bufferreader';
import txs from "nerve-sdk-js/lib/model/txs";
import config from './config';
import { request } from './utils/https';
// const ethUtil = require('ethereumjs-util');

export default {
  name: 'App',
  components: {},
  data() {
    return {
      loading: false,
      accountInfo: {},
      address: '',
      nulsAddress: '',
      pub: '',
      form: {
        contact: '',
        mail: '',
        targetAddress: ''
      },
      rules: {
        contact: [{ required: true, message: this.$t('t7'), trigger: 'change'}],
        mail: [{ required: true, message: this.$t('t8'), trigger: 'change'}],
        targetAddress: [{ required: true, message: this.$t('t10'), trigger: 'change'}],
      },
      accountType: undefined, // 当前地址是否被锁定
      currentChain: config.chain
    };
  },
  watch: {
    nulsAddress(val) {
      if (val) {
        this.checkAccountInfo(val);
      }
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale === 'en' ? 'CN' : 'EN'
    },
    disableSubmit() {
      const { targetAddress, contact, mail } = this.form;
      return !targetAddress || !contact || !mail;
    },
    generateAddressTText() {
      return this.$t('t12') + config.chain + this.$t('t23')
    },
    chainText() {
      const chain = this.currentChain;
      const text = {
        EN: {
          t1: '资产转移目标地址(请在升级后的Nabox钱包中创建新的钱包地址，注意备份好私钥): ',
          t2: '请输入资产转移目标地址',
          t3: '为了帮助你进行账户的解冻和资产的安全转移，请认真填下如下信息，以验证签名消息。',
          t4: `你的邮箱地址(请务必以此邮箱发件至：${ chain === 'NULS' ? 'support@nuls.io' : 'support@nerve.network' }: `,
          t5: `生成${chain}地址`
        },
        CN: {
          t1: `Target ${chain} address (Create a new ${chain} address on the latest version of Nabox): `,
          t2: `Please enter the target ${chain} address`,
          t3: `To assist you to unlock ${chain} account and transfer your assets safely, please submit the following.`,
          t4: `Your e-mail address (Send an e-mail to ${ chain === 'NULS' ? 'support@nuls.io' : 'support@nerve.network' }): `,
          t5: `Generate ${chain} address`
        }
      }
      return text[this.lang]
    },
    chainSymbol() {
      return this.currentChain === 'NULS' ? 'NULS' : 'NVT'
    }
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 1000);
  },
  methods: {
    // 测试签名
   /* async sign() {
      const message = '1fa89949-d7a3-4887-be00-f100f783fa2a:tNULSeBaMrpZFo2MSxFW2H328fSRr6cQiAFW7g'
      const res = await this.personalSign(message);
      console.log(res)
    },*/
    init() {
      if (!window.ethereum || !window.ethereum.selectedAddress) return;
      const address = window.ethereum.selectedAddress;
      this.getAccountInfo(address);
      this.listen();
    },
    getAccountInfo(address) {
      this.address = address;
      const accountList = JSON.parse(localStorage.getItem('accountList')) || [];
      const account = accountList.find(v => (v.address.toLowerCase() === address.toLowerCase() || v.nulsAddress.toLowerCase() === address.toLowerCase())) || null;
      if (account) {
        this.pub = account.pub;
        this.nulsAddress = account.nulsAddress;
      } else {
        this.pub = '';
        this.nulsAddress = '';
      }
    },
    listen() {
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log(accounts, '=====accounts=====')
        if (accounts.length) {
          this.reset();
          this.getAccountInfo(accounts[0])
        } else {
          this.address = ''
        }
      });
    },
    switchLang() {
      const targetLang = this.lang === 'EN' ? 'en' : 'cn'
      localStorage.setItem('lang', targetLang);
      this.$i18n.locale = targetLang
    },
    // 连接钱包
    async connect() {
      if (!window.ethereum) {
        this.$message.warning('Pls install MetaMask first');
      } else {
        try {
          const res = await window.ethereum.request({method: 'eth_requestAccounts'});
          this.getAccountInfo(res[0])
          this.listen();
        } catch (e) {
          this.$message.error(e.message || e);
        }
      }
    },
    // 签名message获取公钥
    async derivedAddress() {
      try {
        let accountInfo = {}
        if (!this.address.startsWith("0x")) {
          if (!window.nabox) {
            throw "Nabox not found"
          }
          if (!this.address.startsWith(config.prefix)) {
            throw 'Chain error'
          }
          this.pub = await window.nabox.getPub({
            address: this.address
          })
          const address = ethers.utils.computeAddress(ethers.utils.hexZeroPad(ethers.utils.hexStripZeros('0x' + this.pub), 33));
          this.nulsAddress = this.address;
          accountInfo = {
            pub: this.pub,
            address,
            nulsAddress: this.address
          }
        } else {
          const message = "Generate NULS address";
          const signature = await this.personalSign(message);
          const msgHash = ethers.utils.hashMessage(message);
          const msgHashBytes = ethers.utils.arrayify(msgHash);
          const recoveredPubKey = ethers.utils.recoverPublicKey(
            msgHashBytes,
            signature
          );
          if (recoveredPubKey.startsWith("0x04")) {
            const compressPub = ethers.utils.computePublicKey(
              recoveredPubKey,
              true
            );
            this.pub = compressPub.slice(2);
            const { chainId, assetId, prefix } = config;
            const nulsAddress = nerve.getAddressByPub(chainId, assetId, this.pub, prefix);
            this.nulsAddress = nulsAddress;
            accountInfo = {
              pub: this.pub,
              address: this.address,
              nulsAddress
            }
          } else {
            throw "Generate NULS address error"
          }
        }
        const accountList = JSON.parse(localStorage.getItem('accountList')) || [];
        accountList.push(accountInfo)
        localStorage.setItem('accountList', JSON.stringify(accountList));
      } catch (e) {
        this.$message.error(e.message || e)
      }
    },
    async personalSign(message) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const jsonRpcSigner = provider.getSigner();
      return await jsonRpcSigner.signMessage(message);
    },
    async createTx() {
      /*const accountList = JSON.parse(localStorage.getItem('accountList')) || [];
      const account = accountList.find(v => v.address.toLowerCase() === this.address.toLowerCase() || v.nulsAddress.toLowerCase() === this.address.toLowerCase());
      console.log(account.address, 132465)*/
      try {
        const { targetAddress, contact, mail } = this.form;
        if (!targetAddress || !contact || !mail) {
          this.$message.warning(this.$t('t11'))
          return;
        }
        this.loading = true
        // await request({ url: '/api/now', method: 'GET' })
        const code = await this.getRandomCode(this.nulsAddress);
        const txHex = await this.getTxHex(code);
        const signHex = await this.appendSignature(txHex);
        console.log(signHex, '===signHex===');
        const finalRes = await this.postForm(signHex, code);
        if (finalRes) {
          this.$message.success(this.$t('t13'))
          this.reset();
        }
      } catch (e) {
        this.$message.warning(e.message || e.msg || e);
      }
      this.loading = false
    },
    async getRandomCode(address) {
      const res = await request({ url: `/api/random/${config.chainId}/${address}` });
      if (res && res.code === 0) {
        return res.data;
      } else {
        throw res;
      }
    },
    async getTxHex(code) {
      const { targetAddress, contact, mail } = this.form;
      const res = await request({
        url: '/api/createTransfer',
        data: {
          address: this.nulsAddress,
          targetAddress,
          contact,
          mail,
          nonce: code,
          chainId: config.chainId
        }
      })
      if (res && res.code === 0) {
        return res.data;
      } else {
        throw res;
      }
    },

    async postForm(hex, code) {
      const { targetAddress, contact, mail } = this.form;
      const res = await request({
        url: '/api/postForm',
        data: {
          address: this.nulsAddress,
          targetAddress,
          contact,
          mail,
          nonce: code,
          txHex: hex,
          chainId: config.chainId
        }
      })
      if (res && res.code === 0) {
        return true
      } else {
        throw res;
      }
    },
    reset() {
      this.form.contact = '';
      this.form.targetAddress = '';
      this.form.mail = '';
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
    },
    // 追加签名
    async appendSignature(txHex) {
      const bufferReader = new BufferReader(Buffer.from(txHex, 'hex'), 0);
      // 反序列回交易对象
      const tAssemble = new txs.Transaction();
      tAssemble.parse(bufferReader);
      const hash = '0x' + tAssemble.getHash().toString('hex');

      const accountList = JSON.parse(localStorage.getItem('accountList')) || [];
      const account = accountList.find(v => v.address.toLowerCase() === this.address.toLowerCase() || v.nulsAddress.toLowerCase() === this.address.toLowerCase());

      const signature = await this.signHash(hash, account.address);
      //初始化签名对象
      const txSignData = new txsignatures.TransactionSignatures();
      // // 反序列化签名对象
      const reader = new BufferReader(tAssemble.signatures, 0);
      txSignData.parse(reader);
      // 追加签名到对象中
      txSignData.addSign(Buffer.from(this.pub, 'hex'), Buffer.from(signature, 'hex'));

      tAssemble.signatures = txSignData.serialize();

      return tAssemble.txSerialize().toString('hex');
    },
    // hash签名
    async signHash(hash, signAddress) {
      hash = hash.startsWith('0x') ? hash : '0x' + hash;
      let flat = await window.ethereum.request({
        method: 'eth_sign',
        params: [signAddress, hash]
      });
      flat = flat.slice(2) // 去掉0x
      const r = flat.slice(0, 64);
      const s = flat.slice(64, 128);
      return new Signature({r, s}).toDER('hex');
    },
    // 查询账户状态和pocm质押情况
    async checkAccountInfo(address) {
      try {
        const res = await request({ url: `/api/state/${config.chainId}/${address}` });
        if (res && res.code === 0) {
          // this.accountType = res.data
          this.accountInfo = res.data
        } else {
          // this.accountType = ''
          this.accountInfo = {}
        }
      } catch (e) {
        this.$message.warning(e.message || e.msg || e);
      }
    },
    superLong(str, len = 10) {
      if (str && str.length > 22) {
        return str.substr(0, len) + "...." + str.substr(str.length - len, str.length);
      } else {
        return str;
      }
    }
  }
};
</script>

<style>

html {
  background-color: #f8fafd;
}
h3, p {
  margin: 0;
}

#app {
  width: 600px;
  margin: 60px auto 0;
}

.title {
  margin: 0 0 20px;
  text-align: center;
}
.tip {
  font-size: 14px;
  margin-bottom: 10px;
  color: #f56c6c;
}
.content {
  padding: 20px 30px 30px;
  border-radius: 20px;
  border: 1px solid #e4e9f4;
  background-color: #fff;
  min-height: 200px;
}
.tc {
  text-align: center;
}

.pt_20 {
  padding-top: 20px;
}
.mr_20 {
  margin: 0 20px;
}

.wd_200 {
  width: 200px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.switch-lang {
  cursor: pointer;
  color: #2688F7;
}
.current-account .el-form-item__label {
  width: 100%;
}
.pocm-list {
  margin-top: -13px;
  padding-bottom: 10px;
}
.pocm-list p, .pocm-list .pocm-item {
  color: #606266;
  font-size: 14px;
  padding: 3px 0;
}
.pocm-list .pocm-item {
  border-bottom: 1px solid #ccc;
}

.message-form {
  /*width: 600px;*/
}

.message-form .el-form-item__label {
  line-height: 20px;
}

.pc {
  display: block !important;
}
.mobile {
  display: none !important;
}

@media screen and (max-width: 1200px) {
  #app {
    width: 100%;
    margin-top: 0;
  }
  .pc {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
}
</style>
