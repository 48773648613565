const cn = {
  t1: '连接钱包',
  t2: '当前地址: ',
  t3: '你的Twitter/TG/Linkdin: ',
  t4: '你的邮箱地址(请务必以此邮箱发件至：support@nuls.io): ',
  t5: '签名信息',
  t6: '提交',
  t7: '请输入你的Twitter/TG/Linkdin',
  t8: '请输入邮箱地址',
  t9: '资产转移目标地址(请在升级后的Nabox钱包中创建新的钱包地址，注意备份好私钥): ',
  t10: '请输入资产转移目标地址',
  t11: '请完善表单信息',
  t12: '生成NULS地址',
  t13: '提交成功',
  t14: '(已锁定)',
  t15: '(未锁定)',
  t16: '验证签名消息',
  t17: '为了帮助你进行账户的解冻和资产的安全转移，请认真填下如下信息，以验证签名消息。',
  t18: '1、请在提交此表单前，务必退出NULS POCM和NULS主网质押的NULS。',
  t19: '2、处于未锁定状态的地址是活跃地址，不需要填写此表单。',
  t20: '锁定数量: ',
  t21: 'POCM质押情况: ',
  t22: '3、申请解锁账户里最少需要0.4个NULS资产。',
  t23: '1、处于未锁定状态的地址是活跃地址，不需要填写此表单。',
  accountType: {
    '': '',
    undefined: '',
    NotLock: '(未锁定)',
    NotApply: '(未申请)',
    Processing: '(待处理)',
    Approval: '(已验证)',
    UnlockNotConfirm: '(解锁交易未确认)',
    TransferNotConfirm: '(转账交易未确认)',
    Finish: '(已完成)'
  }
}
export default cn
