import axios from 'axios';
import config from '../config'


// axios.defaults.timeout = config.API_TIME;
// axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export function post(url, methodName, data = []) {
  // axios.defaults.baseURL = config.API_URL;
  return new Promise((resolve, reject) => {
    //console.log(data);
    const params = {'jsonrpc': '2.0', 'method': methodName, 'params': data, 'id': Math.floor(Math.random() * 1000)};
    //console.log(params);
    axios.post(url, params)
      .then(response => {
        //console.log(response);
        resolve(response.data);
      }, err => {
        reject(err);
      });
  });
}

export async function request(params) {
  const {url, method = 'post', data} = params;
  let baseUrl = config.baseUrl;
  return new Promise((resolve, reject) => {
    //console.log(newData);
    axios({url: baseUrl + url, method: method, data}).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}

