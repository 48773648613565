
const env = process.env.BUILD_ENV;

const NULS_CONFIG = {
  beta: {
    chainId: 2,
    assetId: 1,
    prefix: 'tNULS',
    chain: 'NULS',
    baseUrl: 'http://atappdev.zhoulijun.top',
    // baseUrl: '/test',
    origin: 'http://atappdev.zhoulijun.top'
  },
  prod: {
    chainId: 1,
    assetId: 1,
    prefix: 'NULS',
    chain: 'NULS',
    baseUrl: 'https://at.apply.nuls.io',
    // baseUrl: '/prod',
    origin: 'https://at.apply.nuls.io'
  }
}

const NERVE_CONFIG = {
  beta: {
    chainId: 5,
    assetId: 1,
    prefix: 'TNVT',
    chain: 'NERVE',
    baseUrl: 'http://atappdevn.zhoulijun.top',
    // baseUrl: '/test',
    origin: 'http://atappdevn.zhoulijun.top'
  },
  prod: {
    chainId: 9,
    assetId: 1,
    prefix: 'NERVE',
    chain: 'NERVE',
    baseUrl: 'https://at.apply.nerve.network/',
    // baseUrl: '/test',
    origin: 'https://at.apply.nerve.network/'
  }
}

let config;

const origin = window.location.origin;
const hostname  = window.location.hostname;
if (hostname === 'localhost') {
  // 本地运行 此处需自行调整
  // config = NULS_CONFIG[env];
  config = NERVE_CONFIG[env];
} else {
  config = NULS_CONFIG[env].origin === origin ? NULS_CONFIG[env] : NERVE_CONFIG[env]
}

export default config;