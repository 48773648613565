const en = {
  t1: 'Connect Wallet',
  t2: 'Address: ',
  t3: 'Your Twitter/Telegram/Linkedin: ',
  t4: 'Your e-mail address (Send an e-mail to support@nuls.io): ',
  t5: 'Tx info',
  t6: 'Submit',
  t7: 'Please enter your Twitter/Telegram/Linkedin',
  t8: 'Please enter your email address',
  t9: 'Target NULS address (Create a new NULS address on the latest version of Nabox): ',
  t10: 'Please enter the target NULS address',
  t11: 'Please complete the form information',
  t12: 'Generate NULS address',
  t13: 'Submitted successfully',
  t14: '(Locked)',
  t15: '(Not locked)',
  t16: 'Verify Signature Information',
  t17: 'To assist you to unlock NULS account and transfer your assets safely, please submit the following.',
  t18: '1.Withdraw NULS from consensus and POCM staking before you submit this form',
  t19: '2.Skip if your account is "Notlock".',
  t20: 'Number of locked: ',
  t21: 'POCM stack: ',
  t22: '3.Applying for unlock requires at least 0.4 NULS.',
  t23: '1.Skip if your account is "Notlock".',
  accountType: {
    undefined: '',
    NotLock: '(NotLock)',
    NotApply: '(NotApply)',
    Processing: '(Processing)',
    Approval: '(Approval)',
    UnlockNotConfirm: '(UnlockNotConfirm)',
    TransferNotConfirm: '(TransferNotConfirm)',
    Finish: '(Finish)'
  }
};
export default en
